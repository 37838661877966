import { Box, Divider, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CallLogTableHeader, DATE_PATTERN_DD_MMM_YYYY, DATE_PATTERN_DD_MM_YYYY, DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS, DATE_PATTERN_HH_MM_SS } from '../../../utils/constants';

import { CallLogData } from '../../../types/call-log-data';
import { CallLogMessage } from '../../../types/call-log-message';
import MenuData from '../../../types/ui/menu-data';
import React, { } from 'react';
import Util from '../../../utils/util';
import useStyles from './styles';

interface Props {
  callLogData: CallLogData;
  columns: Array<MenuData>;
  onClick: () => void;
}

/**
 * This component renders a single row in the Call Log table.
 *
 * It takes props including call log data, column visibility information,
 * and an onClick handler for row selection. It displays the date, time,
 * content, recipients, and optionally delivered/read times based on
 * screen size and column visibility.
 *
 * @param {Props} props - The component props.
 * @returns {JSX.Element} JSX element representing a Call Log table row.
 */
const CallLogListTableRow: React.FC<Props> = (props: Props) => {

  const { summary } = props.callLogData;
  const styles = useStyles(summary.flagToDisplay);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  /**
   * Checks if a specific column is visible based on its ID.
   *
   * @param {string} columnId - The ID of the column to check.
   * @returns {boolean} True if the column is visible, false otherwise.
   */
  const isColumnVisible = (columnId: string): boolean => {
    const column = props.columns.find(item => item.id === columnId);

    return (column?.isChecked ?? false);
  }

  /**
   * Finds the earliest delivered at time among call log messages.
   *
   * @param {Array<CallLogMessage>} messageList - The list of messages for a recipient.
   * @returns {string} The formatted earliest delivered at time or '-' if none found.
   */
  const getEarliestDeliveredAt = (messageList: Array<CallLogMessage>): string => {
    let earliestDeliveredAt;
  
    for (const message of messageList) {
      if (message.deliveredAt) {
        if (!earliestDeliveredAt || message.deliveredAt < earliestDeliveredAt) {
          earliestDeliveredAt = message.deliveredAt;
        }
      }
    }
  
    return getFormattedDate(earliestDeliveredAt);
  }

  /**
   * Finds the earliest read at time among call log messages.
   *
   * @param {Array<CallLogMessage>} messageList - The list of messages for a recipient.
   * @returns {string} The formatted earliest read at time or '-' if none found.
   */
  const getEarliestReadAt = (messageList: Array<CallLogMessage>): string => {
    let earliestReadAt;
  
    for (const message of messageList) {
      if (message.readAt) {
        if (!earliestReadAt || message.readAt < earliestReadAt) {
          earliestReadAt = message.readAt;
        }
      }
    }
  
    return getFormattedDate(earliestReadAt);
  }

  /**
   * Formats a date string based on the provided pattern.
   *
   * @param {string|null} date - The date string to format.
   * @returns {string} The formatted date string or '-' if date is null.
   */
  const getFormattedDate = (date?: string | null): string => {

    return date ? Util.formatUTCtoLocal(date, DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS) : '-';
  }

  return (
    <TableRow onClick={props.onClick} sx={styles.tableRow}>
      <TableCell sx={styles.dateTimeCell}>
      <Divider sx={styles.statusMark} />
      <Box sx={styles.dateTimeWrapper}>
        <Typography variant='p1'>{`${Util.formatUTCtoLocal(summary.createdAt, DATE_PATTERN_DD_MM_YYYY)},`}</Typography>
        <Typography variant='p1'>{Util.formatUTCtoLocal(summary.createdAt, DATE_PATTERN_HH_MM_SS)}</Typography>
        </Box>
      </TableCell>
      <TableCell><Typography variant='p1'>{summary.contentText}</Typography></TableCell>
      <TableCell sx={styles.multitLineCell}>
        {summary.recipients.map((recipient, index) => (
          <Typography key={`recipient-${index}`} variant='p1'>{recipient.recipientName}</Typography>
        ))}
      </TableCell>
      {isDesktop &&
        <>
          <TableCell sx={styles.multitLineCell} colSpan={isColumnVisible(CallLogTableHeader.Read) ? 1 : 2}>
            {summary.recipients.map((recipient, index) => (
              <Typography key={`delivered-at-${index}`} variant='p1'>{getEarliestDeliveredAt(recipient.messages)}</Typography>
            ))}
          </TableCell>
          {isColumnVisible(CallLogTableHeader.Read) &&
            <TableCell sx={styles.multitLineCell} colSpan={2}>
              {summary.recipients.map((recipient, index) => (
                <Typography key={`read-at-${index}`} variant='p1'>{getEarliestReadAt(recipient.messages)}</Typography>
              ))}
            </TableCell>
          }
        </>
      }
    </TableRow>
  );
};

export default CallLogListTableRow;

import AppError from "../../exception/app-error";
import { CallLogData } from "../../types/call-log-data";

export const FETCH_CALL_LOG_LIST = 'FETCH_CALL_LOG_LIST';
export const CALL_LOG_LIST_SUCCESS = 'CALL_LOG_LIST_SUCCESS';
export const LOG_API_FAILURE = 'LOG_API_FAILURE';

export interface FetchCallLogListAction {
  type: typeof FETCH_CALL_LOG_LIST;
}

export interface CallLogListSuccessAction {
  type: typeof CALL_LOG_LIST_SUCCESS;
  payload: Array<CallLogData>;
}

export interface LogFailureAction {
  type: typeof LOG_API_FAILURE;
  payload: AppError;
}

export type LogActionTypes = FetchCallLogListAction | CallLogListSuccessAction | LogFailureAction;
import { Box, Typography } from '@mui/material';
import { DATE_PATTERN_DD_MMM_YYYY, MessageOptions } from '../../../../utils/constants';

import ChatUtil from '../../../../utils/chat-util';
import GroupMemberBaseInfo from '../../../../types/group-member-base-info';
import LeftChatItem from './LeftChatItem';
import MessageData from '../../../../types/message-data';
import React from 'react';
import RightChatItem from './RightChatItem';
import { format } from 'date-fns';
import useStyles from './styles';

interface Props {
  nextMessageData?: MessageData;
  messageData: MessageData;
  isCurrentUser: boolean;
  isGroup: boolean;
  senderInfo: GroupMemberBaseInfo;
  disableMessageClick?: boolean;
  handleMsgActionClick: (action: MessageOptions) => void;
  onAttachmentClick: () => void;
}

/**
 * ChatItem Component
 * 
 * This component renders a single chat message item, handling both 
 * left-aligned messages (from other users) and right-aligned messages 
 * (from the current user). It displays the message content, a date header 
 * when the message date differs from the previous message, and utilizes 
 * props to determine alignment and message details.
 * 
 * The component leverages the following:
 *   - `ChatUtil.getDateHeader` to format the date header based on relative position.
 *   - `RightChatItem` and `LeftChatItem` components for specific message rendering.
 */
const ChatItem: React.FC<Props> = (props: Props) => {

  const { nextMessageData, messageData, isCurrentUser } = props;
  const styles = useStyles();

  /**
   * getDateHeader function
   * 
   * This function determines if a date header needs to be displayed 
   * for the current message. It checks the presence of a message creation 
   * date and compares it to the previous message's date (if available). 
   * If the dates differ, it calls `ChatUtil.getDateHeader` to format the 
   * current message's date for the header.
   * 
   * @returns {string} The formatted date header or an empty string.
   */
  const getDateHeader = (): string => {
    let dateHeader = '';
    if (messageData && messageData.createdAt) {
      if (nextMessageData && nextMessageData.createdAt) {
        if (format(nextMessageData.createdAt,
          DATE_PATTERN_DD_MMM_YYYY) !== format(messageData.createdAt, DATE_PATTERN_DD_MMM_YYYY)) {
          dateHeader = ChatUtil.getDateHeader(messageData.createdAt);
        }
      } else {
        dateHeader = ChatUtil.getDateHeader(messageData.createdAt);
      }
    }

    return dateHeader;
  }

  const dateHeader = getDateHeader();

  return (
    <Box>
      {dateHeader &&
        <Typography variant='caption' sx={styles.dateHeader}>{dateHeader}</Typography>
      }
      {isCurrentUser ? <RightChatItem {...props} /> : <LeftChatItem {...props} />}
    </Box>
  );
};

export default ChatItem;

import { Box, Typography } from '@mui/material';

import Dimens from '../../theme/dimens';
import React, { } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  searchKey?: string;
  count: number;
}

/**
 * Renders a header displaying the search results count and a message indicating whether a search term was used.
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} JSX element representing the results header.
 */
const ResultsFoundHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Typography variant='h5' fontWeight={Dimens.fontWeight.medium}>
        {props.searchKey ? t('resultsFor', { value: props.searchKey }) : t('results')}
      </Typography>
      <Typography variant='caption' sx={styles.resultsFound}>
        {t('resultsFound', { value: props.count === 0 ? t('no') : props.count })}
      </Typography>
    </Box>
  );
};

export default ResultsFoundHeader;

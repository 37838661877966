import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

import Dimens from '../../../../theme/dimens';
import React, { } from 'react';
import { getCallLogDetailTableHeaders } from '../../../../utils/ui-constants';

/**
 * Renders the header row for the Call Log Detail table.
 *
 * This component iterates over the table headers defined in `getCallLogDetailTableHeaders` and renders a `TableCell` for each header.
 *
 * @returns {JSX.Element} JSX element representing the table header row.
 */
const CallLogDetailTableHeader: React.FC = () => {

  return (
    <TableHead>
      <TableRow>
        {getCallLogDetailTableHeaders().map((header, index) => (
          <TableCell key={`call-log-detail-table-header-${index}`}>
            <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{header.value}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CallLogDetailTableHeader;

import { Avatar, Box, Stack, Typography } from '@mui/material';

import React, { } from 'react';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  position: string;
  isAdmin: boolean;
  profilePic?: string;
}

/**
 * GroupMemberCard component for displaying information about a group member.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const GroupMemberCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar} src={props.profilePic}>
        <Typography variant='p3' >
          {Util.getInitialsFromName(props.name)}
        </Typography>
      </Avatar>
      <Box sx={styles.contentRight}>
        <Stack sx={styles.contentText}>
          <Typography variant='p1'>
            {props.name}
          </Typography>
          <Typography variant='p1'>
            {props.position}
          </Typography>
        </Stack>
        {props.isAdmin &&
          <Typography variant='p1' sx={styles.role}>{t('admin')}</Typography>
        }
      </Box>
    </Box>
  );
};

export default GroupMemberCard;

import { AppBar, Avatar, Box, IconButton, Toolbar, Typography } from '@mui/material';

import PhoneIcon from '../../../../components/CustomIcons/PhoneIcon';
import React from 'react';
import Util from '../../../../utils/util';
import VideoIcon from '../../../../components/CustomIcons/VideoIcon';
import useStyles from './styles';

/**
 * Interface for ChatHeader component props
 * 
 * @interface Props - The properties expected by the ChatHeader component.
 */
export interface Props {
  /**
   * Name of the contact displayed in the header.
   */
  name: string;
  /**
   * Status of the contact displayed in the header.
   */
  status: string;
  /**
   * Optional profile image URL for the contact.
   */
  image?: string;
  isGroup: boolean;
  onHeaderClick: () => void;
}

/**
 * ChatHeader Component
 * 
 * This component renders the header section of a chat window.
 * It displays the contact's name, status, avatar, and video/phone call buttons.
 */
const ChatHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props);

  return (
    <AppBar position='fixed' sx={styles.appBar}>
      <Toolbar>
        <Avatar sx={styles.avatar} src={props.image} onClick={props.onHeaderClick}>
          {!props.image &&
            <Typography variant='p3'>
              {Util.getInitialsFromName(props.name, props.isGroup ? 1 :2)}
            </Typography>
          }
        </Avatar>
        <Box sx={styles.titleWrapper} onClick={props.onHeaderClick}>
          <Typography variant='h2' sx={styles.title}>
            {props.name}
          </Typography>
          <Typography variant='subheading' sx={styles.subTitle}>
            {Util.toPascalCase(props.status)}
          </Typography>
        </Box>
        {/* <Box>
          <IconButton sx={styles.iconBtn}>
            <VideoIcon sx={styles.icon} />
          </IconButton>
          <IconButton sx={styles.iconBtn}>
            <PhoneIcon sx={styles.icon} />
          </IconButton>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

export default ChatHeader;

import { ActualSessionStatus, AttachmentStatus, MessageSendStatus } from '../../utils/constants';
import React, { useContext } from 'react';
import { apiFailure, chatHistorySuccess, chatSendAPI, chatSendSuccess, fetchChatHistoryAPI, fetchInteractionHistoryAPI, initMessageInfoFetch, interactionHistorySuccess, markChatAsReadAPI, markChatAsReadSuccess, messageInfoSuccess, resetInboxState, updateMessageAttachment, updateSessionStatus, updateSessionStatusSuccess } from './actions';
import { doGet, doMultipartPost, doPost } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import ChatHistoryDispatchPayload from '../../types/chat-history-dispatch-payload';
import ChatHistoryResponse from '../../types/chat-history-response';
import ChatMarkAsReadRequest from '../../types/chat-mark-as-read-request';
import FileUploadResponse from '../../types/file-upload-response';
import GroupChatHistoryResponse from '../../types/group-chat-history-response';
import { HttpStatusCode } from 'axios';
import InteractionHistoryResponse from '../../types/interaction-history-response';
import LoginUtil from '../../utils/login-util';
import MessageData from '../../types/message-data';
import MessageInfoRequest from '../../types/message-info-request';
import MessageInfoResponse from '../../types/message-info-response';
import SendMessageResponse from '../../types/send-message-response';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import SessionStatusRequest from '../../types/session-status-request';
import { Store } from '../../store/store';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useCallback } from 'react';

/**
 * useInboxApi Hook
 * 
 * This hook handles fetching and managing inbox data using API calls and Redux.
 * 
 * @returns {Object} - An object containing functions and state for inbox data.
 */
export function useInboxApi() {

  const { state, dispatch } = React.useContext(Store);
  const { setSessionExpired } = useContext(SessionExpirationContext);

  /**
   * Updates the chat session status.
   *
   * @param {SessionType} sessionType - The type of session (e.g., private, group).
   * @param {ActualSessionStatus} sessionStatus - The new session status.
   */
  const updateChatSessionStatus = useCallback(async (sessionStatus: ActualSessionStatus) => {
    dispatch(updateSessionStatus());
    try {
      const sessionStatusRequest: SessionStatusRequest = {
        loginId: LoginUtil.getLoginId(),
        actualSessionStatus: sessionStatus,
        deviceId: LoginUtil.getClientId()
      };
      await doPost(APIConfig.chatSession, sessionStatusRequest);
      dispatch(updateSessionStatusSuccess(sessionStatusRequest));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Fetches and updates inbox list data. This function is memoized using useCallback for efficiency.
   * 
   * @returns {Promise<void>} - A promise that resolves after fetching and updating inbox data.
   */
  const fetchInteractionHistory = useCallback(async () => {
    dispatch(fetchInteractionHistoryAPI());
    try {
      const url = APIConfig.interactionHistory
        .replace('{login_id}', LoginUtil.getLoginId());
      const response: InteractionHistoryResponse = await doGet(url);
      dispatch(interactionHistorySuccess(response.data || []));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * fetchPrivateChatHistory function
   * 
   * This function fetches the chat history for a private conversation with 
   * the specified `recipientId`. It dispatches an initial API call action 
   * (`initApi`) followed by a success or failure action depending on the 
   * outcome.
   * 
   * @param {string} recipientId - ID of the recipient user.
   * 
   */
  const fetchPrivateChatHistory = useCallback(async (recipientId: string, page: number) => {
    dispatch(fetchChatHistoryAPI());
    try {
      const url = APIConfig.privateChatHistory
        .replace('{login_id}', LoginUtil.getLoginId())
        .replace('{receipient_id}', recipientId)
        .replace('{page}', page.toString());
      const response: ChatHistoryResponse = await doGet(url);
      const payload: ChatHistoryDispatchPayload = {
        roomKey: recipientId,
        chatHistory: response.data
      };
      dispatch(chatHistorySuccess(payload));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * fetchGroupChatHistory function
   * 
   * This function fetches the chat history for a group chat with the specified 
   * `roomKey`. It dispatches an initial API call action (`initApi`) followed 
   * by a success or failure action depending on the outcome.
   *
   * @param {string} roomKey - Key of the group chat room.
   *  
   */
  const fetchGroupChatHistory = useCallback(async (roomKey: string, page: number) => {
    dispatch(fetchChatHistoryAPI());
    try {
      const url = APIConfig.groupChatHistory
        .replace('{login_id}', LoginUtil.getLoginId())
        .replace('{room_key}', roomKey)
        .replace('{page}', page.toString());
      const response: GroupChatHistoryResponse = await doGet(url);
      const payload: ChatHistoryDispatchPayload = {
        roomKey: roomKey,
        chatHistory: response.data.groupMessageResponseList,
        memberList: response.data.memberData
      };
      dispatch(chatHistorySuccess(payload));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * sendChat function
   * 
   * This function sends a chat message using a POST request. 
   * It utilizes the `useCallback` hook to memoize the function, preventing 
   * unnecessary re-renders when its dependencies don't change.
   * 
   * @param messageData {MessageData} Object containing the message data to be sent.
   * 
   * @returns {Promise<SendMessageResponse | undefined>} Promise resolving to the API response 
   * containing the sent message data on success, or undefined on error.
   */
  const sendChat = useCallback(async (messageData: MessageData) => {
    messageData.localMessageId = Util.generateClientId();
    messageData.status = (messageData.file && !messageData.attachmentKey) ? MessageSendStatus.FileUpload
      : MessageSendStatus.InProgress;
    messageData.senderDeviceId = LoginUtil.getClientId();
    messageData.createdAt = new Date().toUTCString();
    dispatch(chatSendAPI(messageData));
    try {
      if (messageData.file && !messageData.attachmentKey) {
        const formData = new FormData();
        formData.append('file', messageData.file);
        const response: FileUploadResponse = await doMultipartPost(APIConfig.chatFileUpload, formData);
        messageData.status = MessageSendStatus.InProgress;
        messageData.attachmentKey = response.data;
        dispatch(chatSendSuccess(messageData));
      }
      const url = messageData.groupRoomKey ? APIConfig.sendGroupChat : APIConfig.sendPrivateChat;
      const response: SendMessageResponse = await doPost(url, messageData);
      const responseData = response.data;
      responseData.status = MessageSendStatus.Sent;
      responseData.localMessageId = messageData.localMessageId;
      responseData.file = messageData.file;
      dispatch(chatSendSuccess(responseData));

      return responseData;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
      messageData.error = (error && error.message) ? error.message : t('defaultErrorMsg');
      messageData.status = MessageSendStatus.Failed;
      dispatch(chatSendSuccess(messageData));
    }
  }, []);

  /**
   * markAsRead function
   * 
   * This function marks unread messages as read by sending a POST request 
   * to the API endpoint defined in `APIConfig.markChatAsRead`.
   * 
   * @param request {ChatMarkAsReadRequest} Object containing details about the messages to mark as read.
   * 
   * @returns {Promise<void>} - This function returns a Promise that resolves 
   * upon successful marking or rejects on error.
   */
  const markAsRead = useCallback(async (request: ChatMarkAsReadRequest) => {
    dispatch(markChatAsReadAPI());
    try {
      await doPost(APIConfig.markChatAsRead, request);
      dispatch(markChatAsReadSuccess(request));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Fetches message information for a specific message.
   *
   * @param {MessageData} messageData - The message data object containing details like message ID
   * and group room key (if applicable).
   * @param {MessageInfoRequest} request - An object specifying the message details needed (e.g., group room key, message ID).
   * 
   * @returns {Promise<MessageInfo | undefined>} A promise that resolves to the fetched message info
   * data on success, or undefined on error.
   */
  const fetchMessageInfo = useCallback(async (messageData: MessageData, request: MessageInfoRequest) => {
    dispatch(initMessageInfoFetch());
    try {
      const response: MessageInfoResponse = await doPost(APIConfig.messageDetail, request);
      messageData.messageInfo = response.data;
      dispatch(messageInfoSuccess(messageData));

      return response.data;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Downloads an attachment associated with a message.
   *
   * @param {MessageData} messageData - The message data containing attachment information.
   * @param {Dispatch<Action>} dispatch - Function to dispatch actions for state updates.
   * 
   * @returns {Promise<Blob | undefined>} A promise that resolves to the downloaded blob on success, or undefined on error.
   */
  const downloadAttachment = useCallback(async (messageData: MessageData) => {
    try {
      messageData.attachmentStatus = AttachmentStatus.DOWNLOADING;
      dispatch(updateMessageAttachment(messageData));
      const response = await doGet(APIConfig.chatFileDownload + messageData.attachmentKey, 'blob');
      const mimeType = Util.getMimeTypeFromPath(messageData.attachmentKey ?? '');
      const blob = new Blob([response], { type: mimeType });
      messageData.file = new File([blob], Util.getFilenameFromPath(messageData.attachmentKey ?? ''),
        {
          type: mimeType
        });
      messageData.attachmentStatus = AttachmentStatus.DOWNLOADED;
      dispatch(updateMessageAttachment(messageData));

      return response;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
      messageData.error = (error && error.message) ? error.message : t('defaultErrorMsg');
      messageData.attachmentStatus = AttachmentStatus.NOT_DOWNLOADED;
      dispatch(updateMessageAttachment(messageData));
    }
  }, []);

  /**
   * Dispatches an `apiFailure` action with an error message for failure scenarios during messaging apis.
   * 
   * @param {Error} error - Optional error object encountered during sign-in/out processes.
   */
  const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
    const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
    if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
  }

  /**
   * Resets the inbox state to its initial state.
   *
   * @param {Dispatch<Action>} dispatch - Function to dispatch actions for state updates.
   */
  const resetInbox = () => {
    dispatch(resetInboxState());
  }

  return {
    updateChatSessionStatus,
    fetchInteractionHistory,
    fetchPrivateChatHistory,
    fetchGroupChatHistory,
    sendChat,
    markAsRead,
    fetchMessageInfo,
    downloadAttachment,
    resetInbox,
    state
  };
}
import {
  CALL_LOG_LIST_SUCCESS,
  CallLogListSuccessAction,
  FETCH_CALL_LOG_LIST,
  FetchCallLogListAction,
  LOG_API_FAILURE,
  LogFailureAction
} from './action-types';

import AppError from '../../exception/app-error';
import { CallLogData } from '../../types/call-log-data';

/**
 * Initializes the fetch call log list action.
 *
 * @returns {FetchCallLogListAction} The fetch call log list action object.
 */
export const initCallLogListFetch = (): FetchCallLogListAction => ({
  type: FETCH_CALL_LOG_LIST
});

/**
 * Creates an action to indicate successful retrieval of call log list.
 *
 * @param {Array<CallLogData>} callLogList The list of call logs.
 * 
 * @returns {CallLogListSuccessAction} The action object.
 */
export const callLogListSuccess = (callLogList: Array<CallLogData>): CallLogListSuccessAction => ({
  type: CALL_LOG_LIST_SUCCESS,
  payload: callLogList
});

/**
 * Creates an action to indicate a failure in retrieving call logs.
 *
 * @param {AppError} error The error object.
 * 
 * @returns {LogFailureAction} The action object.
 */
export const logFailure = (error: AppError): LogFailureAction => ({
  type: LOG_API_FAILURE,
  payload: error
});
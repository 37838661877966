import Dimens from '../../../../theme/dimens'
import { useTheme } from '@mui/material'

/**
 * Custom hook to generate styles for the ProfileAvatar component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {
  const { lg, section } = Dimens.spacing
  const theme = useTheme()

  const styles = {

    avatar: {
      width: Dimens.avatarSize.section,
      height: Dimens.avatarSize.section,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary,
      zIndex: 1
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: `${lg} 0 0 ${lg}`
      }
    },
    cameraBadge: {
      position: 'relative',
      zIndex: 2
    },
    cameraButton: {
      ml: Dimens.spacing.md,
      padding: Dimens.spacing.xs,
      backgroundColor: theme.palette.background.default,
      borderRadius: Dimens.radius.xl 
    },
    cameraIcon: {
      fontSize: Dimens.icon.md
    },
    hiddenInput: {
      display: 'none'
    }
  }

  return styles;
}

export default useStyles;

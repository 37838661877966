import { useTheme } from '@mui/material';

/**
 * Hook to generate styles for the `ResultsFoundHeader` component.
 *
 * @returns {Object} An object containing the styles for the header.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    container: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row'
    },
    resultsFound: {
      color: theme.palette.text.secondary
    }
  };

  return styles;
};

export default useStyles;

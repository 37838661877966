import { IconButton, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';

import { CallLogTableHeader } from '../../../utils/constants';
import Dimens from '../../../theme/dimens';
import DotsVerticalIcon from '../../../components/CustomIcons/DotsVerticalIcon';
import MenuCheckboxDialog from '../../../components/MenuCheckboxDialog';
import MenuData from '../../../types/ui/menu-data';
import React, { } from 'react';
import useStyles from './styles';

interface Props {
  columns: Array<MenuData>;
  onColumnVisibilityChange: (item: MenuData) => void;
}

const CallLogListTableHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * Handles the click event on a sub-menu item.
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event object.
   * 
   * Prevents event propagation and sets the anchor element for the sub-menu.
   */
  const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setSubMenuAnchorEl(event.currentTarget);
  }

  const onSubMenuItemClick = (item: MenuData) => {
    setSubMenuAnchorEl(null);
    props.onColumnVisibilityChange(item);
  }

  return (
    <TableHead>
      <TableRow>
        {props.columns.filter(column => column.isChecked).map((header, index) => (
          <TableCell key={`call-log-table-header-${index}`}>
            <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{header.value}</Typography>
          </TableCell>
        ))}
        {isDesktop &&
          <TableCell sx={styles.optionMenuBtn} align='right'>
            <IconButton onClick={onSubMenuClick}><DotsVerticalIcon /></IconButton>
          </TableCell>
        }
      </TableRow>
      <MenuCheckboxDialog
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        menuList={props.columns.filter(item => item.id === CallLogTableHeader.Read)}
        onMenuItemSelected={onSubMenuItemClick}
      />
    </TableHead>
  );
};

export default CallLogListTableHeader;

import { Box, ListItemButton, Typography } from '@mui/material';
import { ChatRole, TAB_GROUP_MEMBER_ADMIN, TAB_GROUP_MEMBER_ALL } from '../../../../utils/constants';
import { GROUP_MEMBER_TAB_LIST, getGroupMemberSubMenu } from '../../../../utils/ui-constants';
import { REMOVE_GROUP_MEMBER, UPDATE_GROUP_ROLE } from '../../../../data/directory/action-types';
import React, { useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomToggleButtonGroup from '../../../../components/CustomToggleButtonGroup';
import { Group } from '../../../../types/group';
import GroupAddMemberPanel from '../../GroupAddMemberPanel';
import GroupMemberBaseInfo from '../../../../types/group-member-base-info';
import InlineAlert from '../../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../../components/ListItemAddRemoveCard';
import LoginUtil from '../../../../utils/login-util';
import SearchBar from '../../../../components/SearchBar';
import UserAddIcon from '../../../../components/CustomIcons/UserAddIcon';
import Util from '../../../../utils/util';
import { useDirectoryApi } from '../../../../data/directory/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  group: Group;
}

/**
 * MembersTab component for managing group members (listing, roles, and removal).
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MembersTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(TAB_GROUP_MEMBER_ALL);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showAddMemberPanel, setShowAddMemberPanel] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<GroupMemberBaseInfo>();
  const [searchKey, setSearchKey] = useState<string>('');
  const directoryApi = useDirectoryApi();
  const apiStatus = directoryApi.state.directory.apiStatus;

  /**
   * Filters the group member list based on the selected tab (all or admins only).
   *
   * @returns {Array<GroupMemberBaseInfo>} Filtered list of group members.
   */
  const getFilteredMemberList = (): Array<GroupMemberBaseInfo> => {
    const record = directoryApi.state.directory.recordList
      .find(item => item.groupRoomKey === props.group.groupRoomKey);
    let filteredMemberList = (record?.groupMembers || []).filter(member => member.loginId !== LoginUtil.getLoginId() &&
      ((member.name ?? '').toLowerCase().includes(searchKey.toLowerCase())
        || member.loginId?.toLowerCase().includes(searchKey.toLowerCase())));
    if (!Util.isArrayEmpty(filteredMemberList)) {
      if (selectedTab === TAB_GROUP_MEMBER_ADMIN) {
        filteredMemberList = filteredMemberList.filter(member => member.chatRole === ChatRole.Admin);
      }
      filteredMemberList.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
    }

    return filteredMemberList;
  }

  /**
   * Handles the click event on the "Change Role" menu item for a member.
   * Toggles the member's chat role between Admin and User.
   *
   * @param {GroupMemberBaseInfo} member - Member object for whom to change role.
   */
  const onChatRoleChangeClick = (member: GroupMemberBaseInfo) => {
    member.chatRole = (member.chatRole === ChatRole.Admin) ? ChatRole.User : ChatRole.Admin;
    directoryApi.updateGroupRole(member);
  }

  /**
   * Handles the click event on the "Remove" menu item for a member.
   * Opens the confirmation dialog for removing the member.
   *
   * @param {GroupMemberBaseInfo} member - Member object to be removed.
   */
  const onRemoveMemberClick = (member: GroupMemberBaseInfo) => {
    setSelectedMember(member);
    setOpenDeleteDialog(true);
  }

  /**
   * Handles the click event on the confirmation dialog's "OK" button.
   * Removes the selected member from the group.
   */
  const onDeleteClick = () => {
    if (selectedMember) {
      selectedMember.groupRoomKey = props.group.groupRoomKey;
      directoryApi.addOrRemoveGroupMember(selectedMember).then(data => {
        if (data) {
          setSelectedMember(undefined);
        }
      });
    }
    setOpenDeleteDialog(false);
  }

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([REMOVE_GROUP_MEMBER, UPDATE_GROUP_ROLE], apiStatus)} />
      <CustomToggleButtonGroup
        fullWidth
        value={selectedTab}
        data={GROUP_MEMBER_TAB_LIST}
        onChange={(_, value) => value && setSelectedTab(value)}
      />
      <Box sx={styles.contentWrapper}>
        <SearchBar sx={styles.searchBar} placeholder={t('search')} handleChange={setSearchKey} />
        {selectedTab === TAB_GROUP_MEMBER_ALL &&
          <ListItemButton sx={styles.addMemberLayout} onClick={() => setShowAddMemberPanel(true)}>
            <Box sx={styles.addMemberBtn}><UserAddIcon /></Box>
            <Typography variant='p1' sx={styles.addMemberText}>{t('addMembers')}</Typography>
          </ListItemButton>
        }
        {getFilteredMemberList().map((member, index) => (
          <ListItemAddRemoveCard
            key={`group-member-${index}`}
            title={member.name ?? ''}
            subTitle={member.loginId}
            imageUrl={member.thumbnailImageKey}
            isExistingItem={true}
            showHoverMenu
            menu={getGroupMemberSubMenu(member.chatRole === ChatRole.Admin)}
            onMenuItemClick={() => onChatRoleChangeClick(member)}
            onActionClick={() => onRemoveMemberClick(member)}
          />
        ))}
      </Box>
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('removeGrpUser')}
        titleIcon={<BinIcon />}
        message={t('removeGrpUserMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('ok')}
        onPrimaryAction={onDeleteClick}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      <GroupAddMemberPanel
        open={showAddMemberPanel}
        group={props.group}
        onClose={() => setShowAddMemberPanel(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([REMOVE_GROUP_MEMBER, UPDATE_GROUP_ROLE], apiStatus)} />
    </Box>
  );
};

export default MembersTab;
import { Avatar, Box, Stack, Typography } from '@mui/material';

import PagerIcon from '../../../../components/CustomIcons/PagerIcon';
import React, { } from 'react';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  position: string;
  imageUrl?: string;
  isPager?: boolean;
}

/**
 * DepartmentMemberCard component for displaying information about a department member.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const DepartmentMemberCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar} src={props.isPager ? undefined : props.imageUrl}>
        {props.isPager ? (
          <PagerIcon />
        ) : (
          <Typography variant='p3' >
            {Util.getInitialsFromName(props.name)}
          </Typography>
        )}
      </Avatar>
      <Stack sx={styles.contentText}>
        <Typography variant='p1'>
          {props.name}
        </Typography>
        <Typography variant='p1'>
          {props.position}
        </Typography>
      </Stack>
    </Box>
  );
};

export default DepartmentMemberCard;

import { Box } from '@mui/material';
import DepartmentMemberCard from '../../../DepartmentScreen/DepartmentMemberPanel/DepartmentMemberCard';
import React from 'react';
import { User } from '../../../../types/user';
import useStyles from './styles';

interface Props {
  user: User;
}

/**
 * Device Tab Component
 *
 * This component displays information about a user's pager, if available.
 *
 * @param {Props} props Component props containing the user information.
 */
const DeviceTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      {props.user.pager &&
        <DepartmentMemberCard
          name={props.user.pager?.name}
          position={props.user.pager?.destination}
          imageUrl={props.user.image?.thumbnail}
          isPager={true}
        />
      }
    </Box>
  );
};

export default DeviceTab;
import { AssignedSessionStatus, AttachmentType, CallLogDetailTableHeader, CallLogTableHeader, ConversationSubMenu, DepartmentSubMenuItem, DeviceListSubMenu, DeviceTableHeader, DirectoryRecordType, MessageOptions, MessagePriority, PagerStatus, SettingsSubMenu, TAB_DEPT_EDIT_DETAIL, TAB_DEPT_EDIT_MEMBER, TAB_DEPT_EDIT_SETTINGS, TAB_DEVICE_EDIT_ASSIGN, TAB_DEVICE_EDIT_DETAIL, TAB_GROUP_DETAIL, TAB_GROUP_MEMBER, TAB_GROUP_MEMBER_ADMIN, TAB_GROUP_MEMBER_ALL, TAB_GROUP_SETTING, TAB_USER_ACTIVE, TAB_USER_ALL, TAB_USER_DEACTIVATED, TAB_USER_EDIT_DEVICE, TAB_USER_EDIT_PROFILE, TAB_USER_EDIT_SETTINGS, TAB_USER_PENDING, TAB_USER_VIEW_DEVICE, TAB_USER_VIEW_PROFILE, UserListSubMenu, UserProfileStatus, UserTableHeader, languageList } from './constants';

import AppUtil from './app-util';
import BinIcon from '../components/CustomIcons/BinIcon';
import CameraIcon from '../components/CustomIcons/CameraIcon';
import ChevronRightIcon from '../components/CustomIcons/ChevronRightIcon';
import DeactivateIcon from '../components/CustomIcons/DeactivateIcon';
import DotIcon from '../components/CustomIcons/DotIcon';
import FileIcon from '../components/CustomIcons/FileIcon';
import ForwardIcon from '../components/CustomIcons/ForwardIcon';
import HighPriorityIcon from '../components/CustomIcons/HighPriorityIcon';
import InfoCircleIcon from '../components/CustomIcons/InfoCircleIcon';
import MenuData from '../types/ui/menu-data';
import NormalPriorityIcon from '../components/CustomIcons/NormalPriorityIcon';
import PagerIcon from '../components/CustomIcons/PagerIcon';
import PencilIcon from '../components/CustomIcons/PencilIcon';
import PhotoIcon from '../components/CustomIcons/PhotoIcon';
import React from 'react';
import ReloadIcon from '../components/CustomIcons/ReloadIcon';
import SettingsMenuData from '../types/ui/settings_menu_data';
import TabData from '../types/ui/tab-data';
import UnlockIcon from '../components/CustomIcons/UnlockIcon';
import { User } from '../types/user';
import VideoIcon from '../components/CustomIcons/VideoIcon';
import { t } from 'i18next';

/**
 * ui-constants.tsx
 * 
 * This file is used to define UI-related constants which may also include JSX components.
 * If the constants doesn't have any JSX dependency, define that in constants.ts.
 * It helps in centralizing and organizing constants used throughout the UI, such as message
 * priorities with their associated properties and icons.
 */


export const getMessagePriorityList = (isMixedGroup: boolean): Array<MenuData> => [
	{
		id: MessagePriority.Urgent,
		value: t('urgent'),
		isDestructive: true,
		icon: <HighPriorityIcon />
	},
	{
		id: MessagePriority.Normal,
		value: t('normal'),
		isPrimary: true,
		icon: <NormalPriorityIcon />
	},
	...(isMixedGroup ? [ {
		id: MessagePriority.Pager,
		value: t('pager'),
		icon: <PagerIcon />
	} ] : []),
];

export const ATTACHMENT_TYPE_LIST: Array<MenuData> = [
	{
		id: AttachmentType.Camera,
		value: t('camera'),
		icon: <CameraIcon />
	},
	{
		id: AttachmentType.Photo,
		value: t('photo'),
		icon: <PhotoIcon />
	},
	{
		id: AttachmentType.Video,
		value: t('video'),
		icon: <VideoIcon />
	},
	{
		id: AttachmentType.Document,
		value: t('document'),
		icon: <FileIcon />
	}
];

export const CONVERSATION_SUB_MENU: Array<MenuData> = [
	{
		id: ConversationSubMenu.Mute,
		value: t('mute')
	}
];

export const DIRECTORY_TYPE_LIST: Array<MenuData> = [
	{
		id: DirectoryRecordType.Group,
		value: t('groups'),
		isChecked: true
	},
	{
		id: DirectoryRecordType.User,
		value: t('users'),
		isChecked: true
	}
];

export const GROUP_TAB_LIST = [
	{
		id: TAB_GROUP_DETAIL,
		label: t('details')
	},
	{
		id: TAB_GROUP_MEMBER,
		label: t('members')
	},
	// {
	//   id: TAB_GROUP_SETTING,
	//   label: t('settings')
	// }
];

export const GROUP_MEMBER_TAB_LIST = [
	{
		id: TAB_GROUP_MEMBER_ALL,
		label: t('all')
	},
	{
		id: TAB_GROUP_MEMBER_ADMIN,
		label: t('admins')
	}
];

/**
 * Generates the sub-menu options for a group member based on the user's admin status.
 *
 * @param {boolean} isAdmin - Indicates whether the current user is an admin of the group.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the available sub-menu options.
 */
export const getGroupMemberSubMenu = (isAdmin: boolean): Array<MenuData> => ([
	{
		id: 'CHAT_ROLE',
		value: isAdmin ? t('dismissAdmin') : t('makeAdmin')
	}
]);

export const USER_EDIT_TAB_LIST = [
	{
		id: TAB_USER_EDIT_PROFILE,
		label: t('profile')
	},
	{
		id: TAB_USER_EDIT_DEVICE,
		label: t('device')
	},
	// {
	// 	id: TAB_USER_EDIT_SETTINGS,
	// 	label: t('settings')
	// }
];

export const USER_VIEW_TAB_LIST = [
	{
		id: TAB_USER_VIEW_PROFILE,
		label: t('profile')
	},
	{
		id: TAB_USER_VIEW_DEVICE,
		label: t('device')
	}
];

/**
 * Generates a list of available actions for a message based on whether it's from the current user.
 *
 * @param {boolean} isCurrentUser - Indicates whether the message is from the current user.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the available message actions.
 */
export const getMessageActionList = (isCurrentUser: boolean): Array<MenuData> => ([
	...(isCurrentUser ? [ {
		id: MessageOptions.Info,
		value: t('information'),
		icon: <InfoCircleIcon />
	} ] : []),
	{
		id: MessageOptions.Forward,
		value: t('forward'),
		icon: <ForwardIcon />
	},
	// {
	//   id: MessageOptions.Reply,
	//   value: t('reply'),
	//   icon: <ReplyIcon />
	// }
]);

export const DEPT_SUB_MENU: Array<MenuData> = [
	{
		id: DepartmentSubMenuItem.Edit,
		value: t('edit'),
		icon: <PencilIcon />
	},
	{
		id: DepartmentSubMenuItem.Delete,
		value: t('delete'),
		isDestructive: true,
		icon: <BinIcon />
	}
];

export const DEPT_EDIT_TAB_LIST = [
	{
		id: TAB_DEPT_EDIT_DETAIL,
		label: t('details')
	},
	{
		id: TAB_DEPT_EDIT_MEMBER,
		label: t('members')
	},
	{
		id: TAB_DEPT_EDIT_SETTINGS,
		label: t('settings')
	}
];

export const USER_TAB_LIST: Array<TabData> = [
	{
		id: TAB_USER_ALL,
		label: t('all')
	},
	{
		id: TAB_USER_ACTIVE,
		label: t('active')
	},
	{
		id: TAB_USER_PENDING,
		label: t('pending')
	},
	// {
	// 	id: TAB_USER_DEACTIVATED,
	// 	label: t('deactivated')
	// }
];

/**
 * Generates the table headers for the user list, including visibility based on screen size and selected tab.
 *
 * @param {boolean} isDesktop - Indicates whether the screen size is desktop or smaller.
 * @param {string} tab - The currently selected tab.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the table headers.
 */
export const getUserTableHeaders = (isDesktop: boolean, tab: string): Array<MenuData> => ([
	{
		id: UserTableHeader.UserType,
		value: t('userType'),
		isChecked: true
	},
	{
		id: UserTableHeader.Department,
		value: t('department'),
		isChecked: isDesktop
	},
	tab === TAB_USER_PENDING ? {
		id: UserTableHeader.Invited,
		value: t('invited'),
		isChecked: isDesktop
	} : {
		id: UserTableHeader.Position,
		value: t('position'),
		isChecked: isDesktop
	}
]);

/**
 * Generates the sub-menu options for a user based on their profile status.
 *
 * @param {User} user - The user object for whom to generate sub-menu options.
 * 
 * @returns {Array<MenuData>} An array of menu data objects representing the available sub-menu options.
 */
export const getUserListSubMenu = (user: User): Array<MenuData> => {
	const menuList: Array<MenuData> = [];

	const addItem = (id: UserListSubMenu, valueKey: string, icon: JSX.Element, isDestructive = false) => {
		menuList.push({ id, value: t(valueKey), icon, isDestructive });
	};
	if (user.status === UserProfileStatus.Pending) {
		addItem(UserListSubMenu.ResendInvite, 'resendInvite', <ReloadIcon />);
		// addItem(UserListSubMenu.Delete, 'delete', <BinIcon />, true);
	} else {
		addItem(UserListSubMenu.Edit, 'edit', <PencilIcon />);
		// if (user.locked) {
		// 	addItem(UserListSubMenu.Unlock, 'unlock', <UnlockIcon />);
		// }
		// if (user.status === UserProfileStatus.Active) {
		// 	addItem(UserListSubMenu.Deactivate, 'deactivate', <DeactivateIcon />, true);
		// } else if (user.status === UserProfileStatus.Deactivated) {
		// 	addItem(UserListSubMenu.Reactivate, 'reactivate', <ReloadIcon />);
		// 	addItem(UserListSubMenu.Delete, 'delete', <BinIcon />, true);
		// }
	}

	return menuList;
};

export const PROFILE_EDIT_TAB_LIST = [
	{
		id: TAB_USER_EDIT_PROFILE,
		label: t('profile')
	},
	{
		id: TAB_USER_EDIT_SETTINGS,
		label: t('roles')
	}
];

export const PROFILE_SUB_MENU: Array<MenuData> = [
	{
		id: AssignedSessionStatus.Available,
		value: t('available'),
		icon: <DotIcon color='success' />
	},
	{
		id: AssignedSessionStatus.Away,
		value: t('away'),
		icon: <DotIcon color='warning' />
	},
	{
		id: AssignedSessionStatus.DoNotDisturb,
		value: t('doNotDisturb'),
		icon: <DotIcon color='error' />
	}
];


export const SETTINGS_MAIN_MENU: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.Appearance,
		value: t('appearance'),
		icon: <ChevronRightIcon />
	},
	{
		id: SettingsSubMenu.EditProfile,
		value: t('editProfile'),
		icon: <ChevronRightIcon />
	},
	{
		id: SettingsSubMenu.Security,
		value: t('security'),
		icon: <ChevronRightIcon />
	},
	// {
	// 	id: SettingsSubMenu.Notifications,
	// 	value: t('notifications'),
	// 	icon: <ChevronRightIcon />
	// }
];

export const SETTINGS_PARENT_MENU: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.Settings,
		value: t('settings'),
	},
];

export const SETTINGS_LANGUAGE_MENU: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.Language,
		value: t('language'),
		description: AppUtil.getAppLanguage(),
		icon: <ChevronRightIcon />
	},
];

export const SETTINGS_HELP_MENU: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.Help,
		value: t('help'),
		icon: <ChevronRightIcon />
	},
];

export const SETTINGS_LANGUAGE_MENU_OPTIONS: Array<SettingsMenuData> = [
	{
		id: 'en',
		value: 'English',
	},
	{
		id: 'fr',
		value: 'French'
	},
	{
		id: 'de',
		value: 'German'
	},
	{
		id: 'es',
		value: 'Spanish'
	}
];

export const SETTINGS_APPEARANCE_MENU_OPTIONS: Array<SettingsMenuData> = [
	{
		id: 'system',
		value: t('system')
	},
	{
		id: 'light',
		value: t('light')
	},
	{
		id: 'dark',
		value: t('dark')
	}
];

export const SETTINGS_SECURITY_MENU_OPTIONS: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.ChangePassword,
		value: t('changePassword'),
		icon: <ChevronRightIcon />
	},
];

export const SETTINGS_NOTIFICATIONS_MENU_OPTIONS: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.AllowNotifications,
		value: t('allowNotifications'),
		hasToggle: true
	},
];

export const SETTINGS_HELP_MENU_OPTIONS: Array<SettingsMenuData> = [
	{
		id: SettingsSubMenu.ContactSupport,
		value: t('contactSupport'), icon: <ChevronRightIcon />
	},
	{
		id: SettingsSubMenu.PrivacyPolicy,
		value: t('privacyPolicy'), icon: <ChevronRightIcon />
	},
	{
		id: SettingsSubMenu.TermsAndConditions,
		value: t('tandC'), icon: <ChevronRightIcon />
	},
];

export const getDeviceTableHeaders = (isDesktop: boolean): Array<MenuData> => ([
	{
		id: DeviceTableHeader.Identifier,
		value: t('identifier'),
		isChecked: true
	},
	{
		id: DeviceTableHeader.Name,
		value: t('name'),
		isChecked: true
	},
	{
		id: DeviceTableHeader.DeviceType,
		value: t('deviceType'),
		isChecked: isDesktop
	},
	{
		id: DeviceTableHeader.AssignedTo,
		value: t('assignedTo'),
		isChecked: isDesktop
	}
]);

export const DEVICE_LIST_SUB_MENU: Array<MenuData> = [
	{
		id: DeviceListSubMenu.Edit,
		value: t('edit')
	},
	// {
	// 	id: DeviceListSubMenu.Delete,
	// 	value: t('delete'),
	// 	isDestructive: true
	// }
];

export const DEVICE_EDIT_TAB_LIST = [
	{
		id: TAB_DEVICE_EDIT_DETAIL,
		label: t('details')
	},
	{
		id: TAB_DEVICE_EDIT_ASSIGN,
		label: t('assign')
	}
];

export const PROFILE_PHOTO_SELECTION_LIST: Array<MenuData> = [
	{
		id: AttachmentType.Camera,
		value: t('camera'),
		icon: <CameraIcon />
	},
	{
		id: AttachmentType.Photo,
		value: t('photoLibrary'),
		icon: <PhotoIcon />
	}
];

export const CALL_LOG_STATUS_LIST = [
	{
		id: '',
		value: t('select')
	},
	{
		id: PagerStatus.Closed,
		value: t('closed')
	},
	{
		id: PagerStatus.Progress,
		value: t('inProgress')
	},
	{
		id: PagerStatus.Error,
		value: t('error')
	}
];

export const getCallLogTableHeaders = (isDesktop: boolean): Array<MenuData> => ([
	{
		id: CallLogTableHeader.DateNTime,
		value: t('dateAndTime'),
		isChecked: true
	},
	{
		id: CallLogTableHeader.Content,
		value: t('content'),
		isChecked: true
	},
	{
		id: CallLogTableHeader.Recipients,
		value: t('recipients'),
		isChecked: true
	},
	{
		id: CallLogTableHeader.Received,
		value: t('received'),
		isChecked: isDesktop
	},
	{
		id: CallLogTableHeader.Read,
		value: t('read'),
		isChecked: isDesktop
	}
]);

export const getCallLogDetailTableHeaders = (): Array<MenuData> => ([
	{
		id: CallLogDetailTableHeader.Recipients,
		value: t('recipients')
	},
	{
		id: CallLogDetailTableHeader.SentOrReceived,
		value: `${t('sent')}/${t('received')}`
	},
	{
		id: CallLogDetailTableHeader.Read,
		value: t('read')
	},
	// {
	// 	id: CallLogDetailTableHeader.Responded,
	// 	value: t('responded')
	// }
]);
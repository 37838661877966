import React, { useState } from 'react';

import { ADD_GROUP_MEMBER } from '../../../data/directory/action-types';
import { Box } from '@mui/material';
import { ChatRole } from '../../../utils/constants';
import CircularProgressBar from '../../../components/CircularProgressBar';
import { Group } from '../../../types/group';
import GroupMemberBaseInfo from '../../../types/group-member-base-info';
import InlineAlert from '../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../components/ListItemAddRemoveCard';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchBar from '../../../components/SearchBar';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { useDirectoryApi } from '../../../data/directory/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	group: Group;
	onClose: () => void;
}

/**
 * GroupAddMemberPanel component for searching and adding members to a group.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const GroupAddMemberPanel: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const [searchKey, setSeachKey] = useState<string>('');
	const userApi = useUserApi();
	const directoryApi = useDirectoryApi();
	const apiStatus = directoryApi.state.directory.apiStatus;

	/**
   * Filters the user list based on existing group members and sorts alphabetically.
   *
   * @returns {Array<User>} Filtered list of users not already in the group.
   */
	const getFilteredUserList = (): Array<User> => {
		let userList: Array<User> = [];
		if (!Util.isArrayEmpty(userApi.state.user.searchedUserList)) {
			const existingMemberList = props.group.groupMembers?.map(member => member.loginId) ?? [];
			userList = userApi.state.user.searchedUserList.filter(user => !existingMemberList.includes(user.email))
				.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
		}

		return userList;
	}

	// Get the filtered user list based on search and sorting logic.
	const filteredUserList = getFilteredUserList();

	/**
   * Handles adding a user as a member to the group.
   *
   * @param {User} user - User profile data for the new member.
   */
	const addMember = (user: User): void => {
		const member: GroupMemberBaseInfo = {
			groupRoomKey: props.group.groupRoomKey,
			loginId: user.email,
			name: user.name,
			chatRole: ChatRole.User
		};
		directoryApi.addOrRemoveGroupMember(member, true).then(data => {
			if (data) {
				props.onClose();
			}
		})
	}

	/**
   * Handles changes in the search bar input, updating the search query 
   * and fetching users based on the new criteria.
   *
   * @param {string} searchKey Updated search keyword.
   */
	const onSearchChange = (searchKey: string) => {
		setSeachKey(searchKey);
		if (searchKey) {
			userApi.searchUserList(searchKey, 0);
		}
	}

	/**
	 * Handles closing the drawer and updating the selected tab.
	 */
	const onDrawerClose = () => {
		directoryApi.resetError();
		props.onClose();
	}

	return (
		<RightDrawerLayout open={props.open} title={t('addMembers')} onClose={onDrawerClose}>
			<Box sx={styles.container}>
			<InlineAlert message={Util.getApiError([ADD_GROUP_MEMBER], apiStatus)} />
				<SearchBar placeholder={t('search')} handleChange={onSearchChange} />
				<Box sx={styles.contentWrapper}>
					{filteredUserList.map((user, index) => (
						<ListItemAddRemoveCard
							key={`user-profile-${index}`}
							title={user.name}
							imageUrl={user.image?.thumbnail}
							subTitle={user.position ?? ''}
							isExistingItem={false}
							onActionClick={() => addMember(user)}
						/>
					))}
				</Box>
				<CircularProgressBar show={Util.isApiLoading([ADD_GROUP_MEMBER], apiStatus)} />
			</Box>
		</RightDrawerLayout>
	);
};

export default GroupAddMemberPanel;
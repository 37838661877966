import { DEFAULT_PAGE_SIZE } from '../utils/constants';

const APIConfig = {
	baseURL: process.env.REACT_APP_API_URL || '',
	chatBaseURL: process.env.REACT_APP_CHAT_URL || '',
	chatWS: '/ws-point',
	relPath: '/api',
	login: '/login',
	reset: '/reset',
	refreshToken: '/oauth2/refresh',
	logOut: '/logout',
	subscribeRegistration: '/subscriptions?topic=account_registration',
	events: '/events',
	updatePassword: '/accounts/password',
	assignDept: '/accounts/assignDepartment',
	profile: '/profiles',
	department: '/departments',
	deptProfile: '/profiles/department',
	departmentSearch: '/departments?search=',
	site: '/sites',
	siteSearch: '/sites/search?query=',
	interactionHistory: `/chat/interaction/{login_id}/history`,
	privateChatHistory: `/chat/private/{login_id}/{receipient_id}/history?page={page}&size=${DEFAULT_PAGE_SIZE}`,
	groupChatHistory: `/chat/group/{room_key}/{login_id}/history?page={page}&size=${DEFAULT_PAGE_SIZE}`,
	sendPrivateChat: '/chat/private/send',
	sendGroupChat: '/chat/group/send',
  privateChatSubscription: '/user/{login_id}/{device_id}/privateAction',
  groupChatSubscription: '/user/{login_id}/{device_id}/groupAction',
	markChatAsRead: '/chat/interaction/mark',
	messageDetail: '/chat/group/message/detail',
	chatSession: '/chat/session/actual/status',
	chatFileUpload: '/chat/file/upload',
	chatFileDownload: '/chat/file/download?filename=',
	directorySearch: '/directory/search',
	createGroup: '/chat/group/create',
	updateGroup: '/chat/group/update',
	removeGroupMember: '/chat/group/member/remove',
	addGroupMember: '/chat/group/member/add',
	updateGroupRole: '/chat/group/member/role',
	groupMembers: '/chat/group/member/{room_key}',
	searchUsers: `/profiles/search?search={search_key}&page={page}&size=${DEFAULT_PAGE_SIZE}`,
	userList: '/accounts/profiles',
	userDetail: '/accounts/details?email={email_id}',
	updateUser: '/accounts/update',
	userType: '/permission-templates',
	positionList: '/profiles/positions',
	inviteUser: '/invite',
	domainList: '/domains/all',
	addDomain: '/domains/add-all',
	removeDomain: '/domains/remove-all',
	pagerList: '/pagers/search?',
	pagerTypeList: '/pagers/types',
	pager: '/pagers',
	profileImage: '/profiles/picture',
	setProfileStatus: '/chat/session/assigned/status',
	appFeatures: '/features/organization',
	callLog: '/chat/call/logs'
}

export default APIConfig;

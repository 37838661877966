import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import DepartmentMemberCard from '../../DepartmentScreen/DepartmentMemberPanel/DepartmentMemberCard';
import DeviceEditPanel from '../DeviceEditPanel';
import Dimens from '../../../theme/dimens';
import { Pager } from '../../../types/pager';
import PagerIcon from '../../../components/CustomIcons/PagerIcon';
import PencilIcon from '../../../components/CustomIcons/PencilIcon';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import { useDeviceApi } from '../../../data/device/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	pager?: Pager;
	onClose: () => void;
}

/**
 * Device View Panel Component
 *
 * This component displays detailed information about a pager, including its name, type, destination, and assigned user.
 * It also provides a button to edit the pager details.
 *
 * @param {Props} props Component props.
 */
const DeviceViewPanel: React.FC<Props> = (props: Props) => {

	const { open, pager, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [showDeviceEditPanel, setShowDeviceEditPanel] = useState<boolean>(false);
	const deviceApi = useDeviceApi();

	/**
   * Retrieves pager details from the device API state.
   * 
   * @returns {Pager | undefined} Found pager object if available.
   */
	const getPagerDetail = () => {
		const detail = deviceApi.state.device.pagerList.find(item => item.id === pager?.id);

		return detail;
	}

	const pagerDetail = getPagerDetail();

	/**
   * Handles closing the DeviceEditPanel and optionally triggering the main panel close callback.
   * 
   * @param {string} action Optional action string passed from the DeviceEditPanel.
   */
	const onDeviceEditClose = (action?: string) => {
		setShowDeviceEditPanel(false);
		if (action) {
			props.onClose();
		}
	}

	return (
		<RightDrawerLayout
			hasDefaultBg
			open={open}
			rightIcon={pagerDetail ? <PencilIcon /> : undefined}
			onActionClick={() => setShowDeviceEditPanel(true)}
			onClose={onClose}
		>
			{(open && pagerDetail && !showDeviceEditPanel) &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.content}>
						<Box sx={styles.deviceHeader}>
							<Box sx={styles.avatarWrapper}>
								<Box sx={styles.iconWrapper}>
									<PagerIcon />
								</Box>
							</Box>
							<Box sx={styles.deviceContent}>
								<Typography variant='h2' sx={styles.title}>{pagerDetail.name}</Typography>
								<Typography variant='subheading' sx={styles.subTitle}>{pagerDetail.pagerType?.name}</Typography>
								<Typography variant='footer' sx={styles.footer}>{pagerDetail.destination}</Typography>
							</Box>
						</Box>
						{pagerDetail.assignedTo &&
							<Box sx={styles.deviceInfo}>
								<Typography variant='p1' fontWeight={Dimens.fontWeight.semiBold}>{t('assignedTo')}</Typography>
								<DepartmentMemberCard
									name={pagerDetail.assignedTo.name}
									position={pagerDetail.assignedTo.email}
									imageUrl={pagerDetail.assignedTo?.image?.thumbnail}
								/>
							</Box>
						}
					</Box>
				</Box>
			}
			{pagerDetail &&
				<DeviceEditPanel
					open={showDeviceEditPanel}
					pager={pagerDetail}
					onClose={onDeviceEditClose}
				/>
			}
		</RightDrawerLayout>
	);
};

export default DeviceViewPanel;
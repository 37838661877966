import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import ChatItem from '../ChatItem';
import ChatUtil from '../../../../utils/chat-util';
import CheckCircleOutlineIcon from '../../../../components/CustomIcons/CheckCircleOutlineIcon';
import CheckFilledIcon from '../../../../components/CustomIcons/CheckFilledIcon';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import { FETCH_MESSAGE_INFO } from '../../../../data/inbox/action-types';
import GroupMemberBaseInfo from '../../../../types/group-member-base-info';
import MessageData from '../../../../types/message-data';
import MessageInfo from '../../../../types/message-info';
import MessageInfoBody from './MessageInfoBody';
import MessageInfoHeader from './MessageInfoHeader';
import MessageInfoRequest from '../../../../types/message-info-request';
import RightDrawerLayout from '../../../../layouts/RightDrawerLayout';
import Util from '../../../../utils/util';
import { useInboxApi } from '../../../../data/inbox/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	messageData?: MessageData;
	onClose: () => void;
}

/**
 * Renders a drawer component displaying detailed information about a message deliver and read status.
 *
 * @param {Props} props - Component props containing `open`, `messageData`, and `onClose` function.
 * 
 * @returns {JSX.Element} JSX element representing the MessageDetailPanel component.
 */
const MessageDetailPanel: React.FC<Props> = (props: Props) => {

	const { open, messageData, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const inboxApi = useInboxApi();
	const apiStatus = inboxApi.state.inbox.apiStatus;

	// Fetches message info on open, if group message and not already fetched.
	useEffect(() => {
		const messageInfo = getMessageInfo();
		if (open && messageData?.groupRoomKey && (!messageInfo || !messageData.isFetched)) {
			const request: MessageInfoRequest = {
				groupRoomKey: messageData.groupRoomKey,
				messageId: messageData.messageId ?? ''
			};
			inboxApi.fetchMessageInfo(messageData, request);
		}
	}, [open]);

	/**
   * Retrieves message info based on the current message data.
   *
   * @returns {MessageInfo[] | undefined} An array of MessageInfo objects or undefined if not found.
   */
	const getMessageInfo = (): Array<MessageInfo> | undefined => {
		const conversationItem = inboxApi.state.inbox.messageList
			.find(item => messageData?.groupRoomKey ? (item.groupRoomKey === messageData.groupRoomKey) :
				(item.recipientLoginId === messageData?.recipientLoginId));
		const detail = conversationItem?.chatHistory?.content.find(item => item.messageId === messageData?.messageId);

		return detail?.messageInfo;
	}

	/**
   * Gets the list of users who have read the message.
   *
   * @returns {Array<MessageInfo>} An array of MessageInfo objects representing read users.
   */
	const getReadUserList = () => {
		let readUserList: Array<MessageInfo> = [];
		const messageInfo = getMessageInfo();
		if (messageInfo) {
			readUserList = messageInfo.filter(item => Boolean(item.readAt));
		}

		return readUserList;
	}

	const readUserList = getReadUserList();

	/**
   * Gets the list of users who have received the message.
   *
   * @returns {Array<MessageInfo>} An array of MessageInfo objects representing delivered users.
   */
	const getDeliveredUserList = () => {
		let deliveredUserList: Array<MessageInfo> = [];
		const messageInfo = getMessageInfo();
		if (messageInfo) {
			deliveredUserList = messageInfo.filter(item => Boolean(!item.readAt && item.deliveredAt));
		}

		return deliveredUserList;
	}

	const deliveredUserList = getDeliveredUserList();

	/**
	 * Retrieves sender information from the given message data.
	 * This function extracts the sender's name and login ID from the message data and returns them in a GroupMemberBaseInfo object.
	 * @param {MessageData} messageData The message data containing sender information.
	 * @returns {GroupMemberBaseInfo} An object containing the sender's name and login ID.
	 */
	const getSenderInfo = (messageData: MessageData) => {
    const senderInfo: GroupMemberBaseInfo = {
      name: messageData.senderName,
      loginId: messageData.senderLoginId
    };

    return senderInfo;
  }

	return (
		<RightDrawerLayout open={open} title={t('messageInfo')} onClose={onClose}>
			{(open && messageData) &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.header}>
						<ChatItem
							messageData={messageData}
							handleMsgActionClick={_ => {/* Do Nothing */}}
							onAttachmentClick={() => {/* Do Nothing */}}
							isCurrentUser={ChatUtil.isLoggedInUser(messageData.senderLoginId)}
							isGroup={Boolean(messageData.groupRoomKey)}
							senderInfo={getSenderInfo(messageData)}
							disableMessageClick
						/>
					</Box>
					<Box sx={styles.content}>
						<MessageInfoHeader
							title={t('read')}
							icon={<CheckFilledIcon />}
							userList={readUserList}
							date={messageData.readAt}
							isGroup={Boolean(messageData.groupRoomKey)}
						/>
						{!Util.isArrayEmpty(readUserList) && readUserList.map((item: MessageInfo, index: number) => (
							<MessageInfoBody key={`read-at-${index}`} name={item.name} date={messageData.deliveredAt} />
						))}
						<MessageInfoHeader
							title={t('delivered')}
							icon={<CheckCircleOutlineIcon />}
							userList={deliveredUserList}
							date={messageData.deliveredAt}
							isGroup={Boolean(messageData.groupRoomKey)}
						/>
						{!Util.isArrayEmpty(deliveredUserList) && deliveredUserList.map((item: MessageInfo, index: number) => (
							<MessageInfoBody key={`delivered-at-${index}`} name={item.name} date={messageData.deliveredAt} />
						))}
					</Box>
				</Box>
			}
			<CircularProgressBar show={Util.isApiLoading([FETCH_MESSAGE_INFO], apiStatus)} />
		</RightDrawerLayout>
	);
};

export default MessageDetailPanel;
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS, ReceiverCategory } from '../../../../utils/constants';

import { CallLogMessage } from '../../../../types/call-log-message';
import { CallLogRecipient } from '../../../../types/call-log-receipient';
import ChevronDownIcon from '../../../../components/CustomIcons/ChevronDownIcon';
import ChevronUpIcon from '../../../../components/CustomIcons/ChevronUpIcon';
import React, { } from 'react';
import Util from '../../../../utils/util';
import useStyles from './styles';

interface Props {
  recipient: CallLogRecipient;
}

/**
 * Renders a table row for a recipient in the Call Log Detail table.
 *
 * This component displays the recipient name, earliest delivered at time,
 * earliest read at time, and optionally expands to show individual messages
 * for the recipient.
 *
 * @param {Props} props - The component props.
 * @returns {JSX.Element} JSX element representing the table row.
 */
const CallLogDetailTableRow: React.FC<Props> = (props: Props) => {

  const { recipient } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const styles = useStyles(open);

  /**
   * Finds the earliest delivered at time among call log messages.
   *
   * @param {Array<CallLogMessage>} messageList - The list of messages for a recipient.
   * @returns {string} The formatted earliest delivered at time or '-' if none found.
   */
  const getEarliestDeliveredAt = (messageList: Array<CallLogMessage>): string => {
    let earliestDeliveredAt;

    for (const message of messageList) {
      if (message.deliveredAt) {
        if (!earliestDeliveredAt || message.deliveredAt < earliestDeliveredAt) {
          earliestDeliveredAt = message.deliveredAt;
        }
      }
    }

    return getFormattedDate(earliestDeliveredAt);
  }

  /**
   * Finds the earliest read at time among call log messages.
   *
   * @param {Array<CallLogMessage>} messageList - The list of messages for a recipient.
   * @returns {string} The formatted earliest read at time or '-' if none found.
   */
  const getEarliestReadAt = (messageList: Array<CallLogMessage>): string => {
    let earliestReadAt;

    for (const message of messageList) {
      if (message.readAt) {
        if (!earliestReadAt || message.readAt < earliestReadAt) {
          earliestReadAt = message.readAt;
        }
      }
    }

    return getFormattedDate(earliestReadAt);
  }

  /**
   * Formats a date string based on the provided pattern.
   *
   * @param {string|null} date - The date string to format.
   * @returns {string} The formatted date string or '-' if date is null.
   */
  const getFormattedDate = (date?: string | null): string => {

    return date ? Util.formatUTCtoLocal(date, DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS) : '-';
  }

  /**
   * Determines the category label for a message.
   *
   * @param {CallLogMessage} message - The message object.
   * @returns {string} The category label with optional destination information.
   */
  const getRecipientCategory = (message: CallLogMessage): string => {
    let category = Util.toPascalCase(message.category);
    if (message.category === ReceiverCategory.Pager) {
      category = `${Util.toPascalCase(message.category)} (${recipient.destination})`;
    }

    return category;
  }

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={styles.tableRow}>
        <TableCell>
          <Typography variant='p1'>{recipient.recipientName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='p1'>{getEarliestDeliveredAt(recipient.messages)}</Typography>
        </TableCell>
        <TableCell>
          <Box sx={styles.btnLayout}>
            <Typography variant='p1'>{getEarliestReadAt(recipient.messages)}</Typography>
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {open && recipient.messages.map((message, index) => (
        <TableRow key={`message-${index}`} sx={styles.innerRow(recipient.messages.length - 1 === index)}>
          <TableCell colSpan={1}>
            <Typography variant='p1'>{getRecipientCategory(message)}</Typography>
          </TableCell>
          <TableCell colSpan={1}>
            <Typography variant='p1'>{getFormattedDate(message.deliveredAt)}</Typography>
          </TableCell>
          <TableCell colSpan={1}>
            <Typography variant='p1'>{getFormattedDate(message.readAt)}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
};

export default CallLogDetailTableRow;

import '@cyntler/react-doc-viewer/dist/index.css';

import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import DocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';

import { ContentType } from '../../../../utils/constants';
import CrossIcon from '../../../../components/CustomIcons/CrossIcon';
import Dimens from '../../../../theme/dimens';
import MessageData from '../../../../types/message-data';
import React, { } from 'react';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  documents: Array<IDocument>;
  messageData?: MessageData;
}

/**
 * Renders a layout for displaying document attachments.
 *
 * @param {Props} props - Component props containing `onClose`, `documents`, `messageData`, and `containerHeight`.
 * 
 * @returns {JSX.Element} JSX element representing the AttachmentLayout component.
 */
const AttachmentLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  /**
   * Generates a subtitle string based on the message data.
   *
   * @param {MessageData} messageData - The message data object.
   * 
   * @returns {string} The subtitle string.
   */
  const getSubTitle = (messageData: MessageData): string => {

    const date = Util.formatUTCtoLocal(messageData.createdAt);

    return `${t('sentBy')} ${messageData.senderName} ${t('on')} ${date}`;
  }

  /**
   * Renders a PDF document using the DocViewer component.
   *
   * @returns {JSX.Element} The JSX element for rendering the PDF.
   */
  const renderPDF = () => {
    return <DocViewer
      documents={props.documents}
      pluginRenderers={DocViewerRenderers}
      config={{
        header: {
          disableHeader: true
        }
      }}
    />
  }

  /**
   * Renders an image using the CardMedia component.
   *
   * @returns {JSX.Element} The JSX element for rendering the image.
   */
  const renderImage = () => {

    return <CardMedia
      sx={styles.imageView}
      component={'img'}
      src={props.documents[0].uri}
      onContextMenu={e => e.preventDefault()}
    />
  }

  /**
   * Renders a video using the CardMedia component.
   *
   * @returns {JSX.Element} The JSX element for rendering the video.
   */
  const renderVideo = () => {

    return <CardMedia
      sx={styles.videoView}
      component='video'
      src={props.documents[0].uri}
      controls
      controlsList='noplaybackrate nodownload'
      disablePictureInPicture
      onContextMenu={e => e.preventDefault()}
    />
  }

  /**
   * Renders the attachment view based on the file type.
   *
   * Determines the content type of the first document and renders the appropriate component (PDF, image, or video).
   *
   * @returns {JSX.Element} The JSX element for rendering the attachment view.
   */
  const renderAttachmentView = () => {
    if (!Util.isArrayEmpty(props.documents) && props.documents[0].fileName) {
      const contentType = Util.getFileType(props.documents[0].fileName);
      switch (contentType) {
        case ContentType.ContentPdf:
          return renderPDF();
        case ContentType.ContentImage:
          return renderImage();
        case ContentType.ContentVideo:
          return renderVideo();
      }
    }

    return <></>;
  }

  return (
    <Box sx={styles.attachmentLayout}>
      {props.messageData ? (
        <Box sx={styles.viewheader}>
          <Box sx={styles.titleLayout}>
            <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{props.documents[0].fileName}</Typography>
            <Typography variant='footer'>{getSubTitle(props.messageData)}</Typography>
          </Box>
          <IconButton onClick={props.onClose}><CrossIcon /></IconButton>
        </Box>
      ) : (
        <Box sx={styles.editHeader}>
          <IconButton onClick={props.onClose}><CrossIcon /></IconButton>
          <Typography variant='h3' fontWeight={Dimens.fontWeight.bold}>{props.documents[0].fileName}</Typography>
        </Box>
      )}
      {renderAttachmentView()}
    </Box>
  );
};

export default AttachmentLayout;

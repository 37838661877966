import { Box, Typography } from '@mui/material';

import CustomLabel from '../../../../components/CustomLabel';
import React, { } from 'react';
import useStyles from './styles';

interface Props {
  label: string;
  value: string;
  icon?: JSX.Element;
}

/**
 * This component displays a label and value pair with an optional icon.
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} JSX element representing the label-value pair.
 */
const CallLogMessageInfoItem: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <CustomLabel label={props.label} />
      <Box sx={styles.innerWrapper}>
        {props.icon}
        <Typography variant='p1'>{props.value}</Typography>
      </Box>
    </Box>
  );
};

export default CallLogMessageInfoItem;

import AppError from '../../exception/app-error';
import { DirectoryRecord } from '../../types/directory-record';

export const RESET_ERROR_STATE = 'RESET_DIRECTORY_ERROR_STATE';
export const SEARCH_DIRECTORY = 'SEARCH_DIRECTORY';
export const SEARCH_DIRECTORY_SUCCESS = 'SEARCH_DIRECTORY_SUCCESS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const FETCH_GROUP_MEMBERS = 'FETCH_GROUP_MEMBERS';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const REMOVE_GROUP_MEMBER = 'REMOVE_GROUP_MEMBER';
export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER';
export const UPDATE_GROUP_ROLE = 'UPDATE_GROUP_ROLE';
export const API_FAILURE = 'DIRECTORY_API_FAILURE';
export const RESET_DIRECTORY_STATE = 'RESET_DIRECTORY_STATE';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface SearchDirectoryAction {
  type: typeof SEARCH_DIRECTORY;
}

export interface SearchDirectorySuccessAction {
  type: typeof SEARCH_DIRECTORY_SUCCESS;
  payload: Array<DirectoryRecord>;
}

export interface CreateGroupAction {
  type: typeof CREATE_GROUP;
}

export interface UpdateGroupAction {
  type: typeof UPDATE_GROUP;
}

export interface AddGroupMemberAction {
  type: typeof ADD_GROUP_MEMBER;
}

export interface RemoveGroupMemberAction {
  type: typeof REMOVE_GROUP_MEMBER;
}

export interface UpdateGroupRoleAction {
  type: typeof UPDATE_GROUP_ROLE;
}

export interface FetchGroupMembersAction {
  type: typeof FETCH_GROUP_MEMBERS;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export interface DirectoryStateResetAction {
  type: typeof RESET_DIRECTORY_STATE;
}

export type DirectoryActionTypes = ResetErrorStateAction | SearchDirectoryAction | SearchDirectorySuccessAction
  | CreateGroupAction | UpdateGroupAction | ApiFailureAction | FetchGroupMembersAction | AddGroupMemberAction
  | RemoveGroupMemberAction | UpdateGroupRoleAction | DirectoryStateResetAction;
import { AUDIO_EXT_WAV, AUDIO_MIME_TYPE, AppFeature, ContentType, DATE_PATTERN_DD_MM_YYYY, PWD_MIN_LENGTH, REGEX_PATTERN_LOWER_CASE, REGEX_PATTERN_NUMBER, REGEX_PATTERN_PATH_EXTENSION, REGEX_PATTERN_PWD_SPECIAL_CHAR, REGEX_PATTERN_UPPER_CASE, fileTypes, mimeTypes } from './constants';

import APIStatus from '../types/api-status';
import APP_NAV from '../routes/app-nav';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import LoginUtil from './login-util';
import ValidationItem from '../types/ui/validation-item';
import { format } from 'date-fns';
import { t } from 'i18next';

/**
 * Utility class for various helper functions.
 */
export default class Util {

  /**
   * Gets the trimmed text from a given string.
   *
   * This function takes an optional string as input. If the string is null or undefined, it returns an empty string. Otherwise, it returns the trimmed string.
   *
   * @param {string|undefined} value The input string.
   * 
   * @returns {string} The trimmed string.
   */
  public static getText(value?: string): string {
    
    return (value ?? '').trim();
  }

  /**
   * Generates URL parameters from an object.
   *
   * Removes empty properties from the object and then constructs a URL parameter string by joining key-value pairs.
   *
   * @param {any} object The object containing key-value pairs to be converted into URL parameters.
   * 
   * @returns {string} The generated URL parameter string.
   */
  // eslint-disable-next-line
  public static generateUrlParams(object: any): string {
    // eslint-disable-next-line
    const obj: any = Util.removeEmpty(object);

    return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&');
  }

  /**
   * Removes empty properties from an object recursively.
   *
   * Traverses the object and deletes properties with null or empty values. If a property is an object, it recursively removes empty properties from it.
   *
   * @param {any} obj The object to be modified.
   * 
   * @returns {any} The modified object with empty properties removed.
   */
  // eslint-disable-next-line
  public static removeEmpty(obj: any) {
    Object.entries(obj).forEach(([key, val]) =>
      ((val && typeof val === 'object') && Util.removeEmpty(val)) ||
      ((val === null || val === '') && delete obj[key])
    );

    return obj;
  }

  /**
   * Converts a UTC time string to a local time Date object.
   * 
   * @param {string} utcTimeString The UTC time string to convert.
   * @returns {Date} The converted local time Date object.
   */
  public static UTCtoLocalTime(utcTimeString: string): Date {
    const utcDate = new Date(utcTimeString);
    const localTime = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

    return localTime;
  }

  /**
   * Formats a UTC date object into a human-readable string using a specified pattern.
   * 
   * This function likely utilizes an external library for date formatting (date-fns).
   *
   * @param {Date} utcDate - The UTC date object to be formatted.
   * @param {string} pattern - The desired format pattern for the output string (e.g., 'DD-MM-YYYY', 'YYYY-MM-DD hh:mm').
   * @returns {string} - The formatted date string based on the provided pattern, representing the local time equivalent of the UTC date.
   */
  public static formatUTCtoLocal(utcDate?: Date | string, pattern?: string): string {
    let formattedDate = '';
    if (utcDate) {
      formattedDate = format(utcDate, pattern ?? DATE_PATTERN_DD_MM_YYYY);
    }

    return formattedDate;
  }

  /**
   * Converts a local date to UTC.
   *
   * This function takes a local date and adjusts it by subtracting the local timezone offset to obtain the equivalent UTC date.
   *
   * @param {Date} date The local date to be converted.
   * 
   * @returns {Date} The UTC equivalent of the input date.
   */
  public static convertToUTC(date: Date): Date {
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    
    return utcDate;
  }

  /**
   * Checks password validation status against different criteria.
   *
   * This static function takes a password string and returns an array of `ValidationItem` objects.
   * Each `ValidationItem` object has a `label` (translated message) and an `isValid` flag
   * indicating if the password meets the corresponding criteria. The criteria include:
   *  - Minimum length (defined by `PWD_MIN_LENGTH`)
   *  - Lowercase character presence (using `REGEX_PATTERN_LOWER_CASE`)
   *  - Uppercase character presence (using `REGEX_PATTERN_UPPER_CASE`)
   *  - Number presence (using `REGEX_PATTERN_NUMBER`)
   *  - Special character presence (using `REGEX_PATTERN_PWD_SPECIAL_CHAR`)
   *
   * This function can be used to display password validation feedback to the user
   * based on the returned `ValidationItem` objects.
   *
   * @param {string} password - The password string to validate.
   * @returns {Array<ValidationItem>} - An array of objects with validation status details.
   */
  public static getPasswordValidationStatus(password: string): Array<ValidationItem> {

    return [
      {
        label: t('pwdMinLength'),
        isValid: password.length >= PWD_MIN_LENGTH
      },
      {
        label: t('pwdLowerCase'),
        isValid: REGEX_PATTERN_LOWER_CASE.test(password)
      },
      {
        label: t('pwdUpperCase'),
        isValid: REGEX_PATTERN_UPPER_CASE.test(password)
      },
      {
        label: t('pwdNumber'),
        isValid: REGEX_PATTERN_NUMBER.test(password)
      },
      {
        label: t('pwdSpecialChar'),
        isValid: REGEX_PATTERN_PWD_SPECIAL_CHAR.test(password)
      }
    ];
  }

  /**
   * Generates a random client ID string.
   *
   * This function uses the window.crypto API to generate a cryptographically
   * secure random byte array. It then converts the byte array to a hex string
   * and a base64 encoded string. The base64 encoded string is returned as the
   * client ID.
   *
   * @returns {string} - The generated random client ID string.
   */
  public static generateClientId(): string {

    const randomBytes = new Uint8Array(128);
    window.crypto.getRandomValues(randomBytes);
    const hexString: Array<string> = [];
    randomBytes.forEach((b) => hexString.push(b.toString(16).padStart(2, '0')));
    const clientId = btoa(String.fromCharCode(...randomBytes));

    return clientId;
  }

  /**
   * Checks if an array is empty.
   *
   * This function takes an array as input and returns true if the array is either null or undefined,
   * or if the array has a length of zero (meaning it contains no elements).
   *
   * @param {Array<any>} array - The array to check for emptiness.
   * @returns {boolean} - True if the array is empty, false otherwise.
   */
  public static isArrayEmpty(array?: Array<any>): boolean { /* eslint-disable-line */

    return (!array || array.length === 0);
  }

  /**
   * getInitialsFromName function
   * 
   * This function extracts initials from a given name string.
   * 
   * @param {string} name - The full name string.
   * @param {number} charCount (optional) - The desired number of initials (defaults to 2).
   * @returns {string} - The extracted initials in uppercase format.
   */
  public static getInitialsFromName(name: string, charCount = 2): string {
    // Handle empty or single-word names
    if (!name || name.trim() === '') {
      return '';
    }

    const words = name.trim().split(' ');
    let initials = '';

    // Extract initials based on charCount
    for (let i = 0; i < Math.min(words.length, charCount); i++) {
      initials += words[i].charAt(0).toUpperCase();
    }

    return initials.toUpperCase();
  }

  /**
   * Converts a string to PascalCase (UpperCamelCase).
   * This function converts a string with spaces into PascalCase format by capitalizing the first letter of each word.
   * @param {string} str - The input string.
   * @returns {string} The converted string in PascalCase format.
   */
  public static toPascalCase(str: string): string {

    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  /**
   * Extracts the error message for a specific API task from the API status.
   *
   * @param {Array<string>} taskList - The list of task Ids.
   * @param {APIStatus | undefined} apiStatus - The API status object.
   * 
   * @returns {string | undefined} - The error message if the task is in error state, otherwise undefined.
   */
  public static getApiError(taskList: Array<string>, apiStatus: APIStatus | undefined): string | undefined {

    return taskList.includes(apiStatus?.task ?? '') ? apiStatus?.error : undefined;
  }

  /**
   * Checks if a specific API task is currently loading.
   *
   * @param {Array<string>} taskList - The list of task Ids.
   * @param {APIStatus | undefined} apiStatus - The API status object.
   * 
   * @returns {boolean} - True if the task is loading, false otherwise.
   */
  public static isApiLoading(taskList: Array<string>, apiStatus: APIStatus | undefined): boolean {

    return Boolean(taskList.includes(apiStatus?.task ?? '') && apiStatus?.isLoading);
  }

  /**
   * Formats a number with abbreviations for large values (K, M, B).
   *
   * @param {number} num - The number to be formatted.
   * 
   * @returns {string} The formatted number string.
   */
  public static formatNumber(num: number): string {
    let formattedNum = num.toString();
    if (num >= 1_000_000_000) {
      formattedNum = Math.floor(num / 1_000_000_000) + 'B';
    } else if (num >= 1_000_000) {
      formattedNum = Math.floor(num / 1_000_000) + 'M';
    } else if (num >= 1_000) {
      formattedNum = Math.floor(num / 1_000) + 'K';
    }

    return formattedNum;
  }

  /**
   * Determines the file type based on the file extension.
   * 
   * @param {string} filename - The name of the file to check.
   * 
   * @returns {ContentType} - The content type such as 'CONTENT_PDF', 'CONTENT_VIDEO', 'CONTENT_IMAGE', or 'CONTENT_FILE' if not recognized.
   */
  public static getFileType(filename: string): ContentType {
    const extension = filename.split('.').pop()?.toLowerCase();

    return extension && fileTypes[extension] ? fileTypes[extension] : ContentType.ContentFile;
  }

  /**
   * Extracts the filename from a given relative path.
   * 
   * @param {string} path - The relative path from which to extract the filename.
   * 
   * @returns {string} - The filename, or an empty string if the path does not contain a filename.
   */
  public static getFilenameFromPath(path: string): string {
    const match = path.match(/[0-9]+_(.+)$/);
    
    return (match && match.length > 0) ? match[1] : path;
  }

  /**
   * Extracts the file extension from a given path.
   *
   * @param {string} path - The file path.
   * 
   * @returns {string} The file extension (e.g., 'pdf', 'jpg', 'txt').
   */
  public static getExtensionFromPath(path: string): string {
    const match = path.match(REGEX_PATTERN_PATH_EXTENSION);

    return match && match.length > 1 ? match[1].toLowerCase() : 'txt';
  }

  /**
   * Gets the MIME type based on the file extension.
   *
   * @param {string} path - The file path.
   * 
   * @returns {string} The MIME type corresponding to the file extension.
   */
  public static getMimeTypeFromPath(path: string): string {
    const ext = Util.getExtensionFromPath(path);

    return mimeTypes[ext] ?? mimeTypes['txt'];
  }

  /**
   * Formats a file size in bytes into a human-readable format with units (e.g., "1.23 MB").
   *
   * @param {number} sizeInBytes - The file size in bytes.
   * 
   * @returns {string} The formatted file size string.
   */
  public static formatFileSize(sizeInBytes: number): string {
    if (sizeInBytes === 0) {
      return '0 Bytes';
    }
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    const size = (sizeInBytes / Math.pow(1024, i)).toFixed(2); // Keep two decimal places

    return `${size} ${units[i]}`;
  }

  /**
   * Converts a base64 string to a Blob object.
   *
   * @param {string} base64String - The base64 string to convert.
   * 
   * @returns {Blob} The converted Blob object.
   */
  public static convertBase64ToBlob(base64String: string): Blob {
    const bytes = atob(base64String.split(',')[1]);
    const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];
    const array = new Uint8Array(bytes.length);
    for (let i = 0; i < bytes.length; i++) {
      array[i] = bytes.charCodeAt(i);
    }

    return new Blob([array], { type: mimeType });
  }

  /**
   * Converts a Blob object to a File object.
   *
   * @param {Blob} blob - The Blob object to convert.
   * @param {string} fileName - The desired file name.
   * 
   * @returns {File} The converted File object.
   */
  public static convertBlobToFile(blob: Blob, fileName: string): File {

    return new File([blob], fileName, { type: blob.type });
  }

  /**
   * Converts a WebM blob to a WAV blob.
   *
   * Uses FFmpeg to convert the WebM audio data to WAV format.
   *
   * @param {Blob} webmBlob The WebM blob to be converted.
   * @param {string} fileName The desired file name for the WAV output.
   * 
   * @returns {Promise<Blob>} A promise that resolves with the converted WAV blob.
   */
  public static async convertWebmToWav(webmBlob: Blob, fileName: string): Promise<Blob> {
    const outputFileName = fileName + AUDIO_EXT_WAV;
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    await ffmpeg.writeFile('input.webm', new Uint8Array(await webmBlob.arrayBuffer()));
    await ffmpeg.exec(['-i', 'input.webm', '-c:a', 'pcm_s16le', outputFileName]);
    const outputData = await ffmpeg.readFile(outputFileName);
    const outputBlob = new Blob([outputData], {
      type: AUDIO_MIME_TYPE,
    });

    return outputBlob;
  }

  /**
   * Determines the appropriate navigation route based on the user's permissions.
   *
   * If the user has the `ManageUser` permission, returns the `APP_NAV.ADMIN_USERS` route.
   * Otherwise, returns the `APP_NAV.ADMIN_DEVICES` route.
   *
   * @returns {string} The navigation route string.
   */
  public static getNavigationByPermission(): string {

    return LoginUtil.hasPermission(AppFeature.ManageUser) ? APP_NAV.ADMIN_USERS : APP_NAV.ADMIN_DEVICES;
  }

}
import { Box, ListItemButton, Typography } from '@mui/material';
import React, { useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import AssignDevicePanel from '../../AssignDevicePanel';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import InlineAlert from '../../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../../components/ListItemAddRemoveCard';
import SwapIcon from '../../../../components/CustomIcons/SwapIcon';
import { UPDATE_USER_DETAIL } from '../../../../data/user/action-types';
import { User } from '../../../../types/user';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../../data/user/api';

interface Props {
  user: User;
}

/**
 * Device Tab Component
 *
 * This component displays information about a user's pager, including a button to assign or reassign a pager.
 * It also handles showing a confirmation dialog for unassigning a pager.
 *
 * @param {Props} props Component props containing the user information.
 */
const DeviceTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [showAssignDevicePanel, setShowAssignDevicePanel] = useState<boolean>(false);
  const [showUnAssignAlert, setShowUnAssignAlert] = useState<boolean>(false);
  const userApi = useUserApi();
  const apiStatus = userApi.state.user.apiStatus;

  /**
   * Handles unassigning a device from the user.
   */
  const unAssignDevice = () => {
    const request = {
      ...props.user
    };
    request.pager = undefined;
    userApi.updateUserDetail(request);
    setShowUnAssignAlert(false);
  }

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([UPDATE_USER_DETAIL], apiStatus)} />
      <ListItemButton sx={styles.addMemberLayout} onClick={() => setShowAssignDevicePanel(true)}>
        <Box sx={styles.addMemberBtn}><SwapIcon /></Box>
        <Typography variant='p1' sx={styles.addMemberText}>{props.user.pager ? t('reassign') : t('assign')}</Typography>
      </ListItemButton>
      {props.user.pager &&
        <ListItemAddRemoveCard
          title={props.user.pager?.name}
          subTitle={props.user.pager?.destination}
          imageUrl={props.user.image?.thumbnail}
          isExistingItem={true}
          onActionClick={() => setShowUnAssignAlert(true)}
        />
      }
      <AlertDialogSmall
        open={showUnAssignAlert}
        title={t('unassignDevice')}
        titleIcon={<BinIcon />}
        message={t('unassignDeviceMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setShowUnAssignAlert(false)}
        primaryLabel={t('ok')}
        onPrimaryAction={unAssignDevice}
        isDestructive
        onClose={() => setShowUnAssignAlert(false)}
      />
      <AssignDevicePanel
        open={showAssignDevicePanel}
        user={props.user}
        onClose={() => setShowAssignDevicePanel(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([UPDATE_USER_DETAIL], apiStatus)} />
    </Box>
  );
};

export default DeviceTab;
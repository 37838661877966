import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Hook to generate styles for the Call Log message info item component.
 *
 * @returns {Object} An object containing the styles for the component.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { xs, md, lg } = Dimens.spacing;

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    innerWrapper: {
      p: `${md} ${lg}`,
      display: 'flex',
      flexDirection: 'row',
      bgcolor: theme.palette.background.default,
      borderRadius: Dimens.radius.md,
      alignItems: 'center',
      '& svg': {
        ...commonStyles.smIcon,
        mr: xs
      }
    }
  };

  return styles;
};

export default useStyles;

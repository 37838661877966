import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';

import DeactivatedIcon from '../CustomIcons/DeactivatedIcon';
import DotsVerticalIcon from '../CustomIcons/DotsVerticalIcon';
import MenuData from '../../types/ui/menu-data';
import MenuSelectDialog from '../MenuSelectDialog';
import PlusCircleIcon from '../CustomIcons/PlusCircleIcon';
import React, { } from 'react';
import Util from '../../utils/util';
import useStyles from './styles';

interface Props {
  title: string;
  subTitle: string;
  imageUrl?: string;
  isExistingItem: boolean;
  showHoverMenu?: boolean;
  menu?: Array<MenuData>;
  onMenuItemClick?: (item: MenuData) => void;
  onActionClick: () => void;
}

/**
 * ListItemAddRemoveCard component for displaying an item with an add or remove action.
 *
 * @param {Object} props Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ListItemAddRemoveCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuItemClick = (item: MenuData) => {
    if (props.onMenuItemClick) {
      setSubMenuAnchorEl(null);
      props.onMenuItemClick(item);
    }
  }

  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar} src={props.imageUrl}>
        <Typography variant='p3' >
          {Util.getInitialsFromName(props.title)}
        </Typography>
      </Avatar>
      <Box sx={styles.contentRight}>
        <Stack sx={styles.contentText}>
          <Typography variant='p1' sx={styles.primaryText}>
            {props.title}
          </Typography>
          <Typography variant='p1' sx={styles.primaryText}>
            {props.subTitle}
          </Typography>
        </Stack>
        <Stack sx={styles.action}>
          {props.showHoverMenu &&
            <IconButton sx={styles.iconToggle} onClick={e => setSubMenuAnchorEl(e.currentTarget)}>
              <DotsVerticalIcon />
            </IconButton>
          }
          <IconButton onClick={props.onActionClick}>
            {props.isExistingItem ? (
              <DeactivatedIcon color='error' />
            ) : (
              <PlusCircleIcon color='primary' />
            )}
          </IconButton>
        </Stack>
      </Box>
      <MenuSelectDialog
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        menuList={props.menu ?? []}
        onMenuItemSelected={handleMenuItemClick}
      />
    </Box>
  );
};

export default ListItemAddRemoveCard;

import {
	CALL_LOG_LIST_SUCCESS,
	FETCH_CALL_LOG_LIST,
	LOG_API_FAILURE,
	LogActionTypes
} from './action-types';

import LogState from '../../types/states/log-state';

export const logInitialState: LogState = {
  callLogList: [],
	apiStatus: undefined
};

/**
 * Reducer function to handle various actions related to call logs.
 *
 * @param {LogState} state - The current state of the log reducer.
 * @param {LogActionTypes} action - The action to be handled.
 * @returns {LogState} The updated state.
 */
const logReducer = (state: LogState, action: LogActionTypes): LogState => {
  switch (action.type) {
		case FETCH_CALL_LOG_LIST:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
			case CALL_LOG_LIST_SUCCESS:
				return {
					...state,
					callLogList: action.payload,
					apiStatus: {
						...state.apiStatus,
						isLoading: false,
						data: action.payload,
						isCompleted: true
					}
				};
			case LOG_API_FAILURE:
				return {
					...state,
					callLogList: [],
					apiStatus: {
						...state.apiStatus,
						isLoading: false,
						error: action.payload.message,
						isCompleted: true
					}
				};
		default:
			return state;
  }
};

export default logReducer;
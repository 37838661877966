import AppError from '../../exception/app-error';
import Permission from '../../types/permission';
import UserType from '../../types/user-type';

export const FETCH_USER_TYPE_LIST = 'FETCH_USER_TYPE_LIST';
export const USER_TYPE_LIST_SUCCESS = 'USER_TYPE_LIST_SUCCESS';
export const FETCH_APP_FEATURE_LIST = 'FETCH_APP_FEATURE_LIST';
export const APP_FEATURE_LIST_SUCCESS = 'APP_FEATURE_LIST_SUCCESS';
export const CREATE_USER_TYPE = 'CREATE_USER_TYPE';
export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
export const DELETE_USER_TYPE = 'DELETE_USER_TYPE';
export const API_FAILURE = 'USER_TYPE_API_FAILURE';
export const RESET_USER_TYPE_STATE = 'RESET_USER_TYPE_STATE';

export interface FetchUserTypeListAction {
	type: typeof FETCH_USER_TYPE_LIST;
}

export interface UserTypeListSuccessAction {
	type: typeof USER_TYPE_LIST_SUCCESS;
	payload: Array<UserType>;
}

export interface FetchAppFeatureListAction {
	type: typeof FETCH_APP_FEATURE_LIST;
}

export interface AppFeatureListSuccessAction {
	type: typeof APP_FEATURE_LIST_SUCCESS;
	payload: Array<Permission>;
}

export interface CreateUserTypeAction {
	type: typeof CREATE_USER_TYPE;
}

export interface UpdateUserTypeAction {
	type: typeof UPDATE_USER_TYPE;
}

export interface DeleteUserTypeAction {
	type: typeof DELETE_USER_TYPE;
}

export interface ApiFailureAction {
	type: typeof API_FAILURE;
	payload: AppError;
}

export interface UserTypeStateResetAction {
	type: typeof RESET_USER_TYPE_STATE;
}

export type UserTypeActionTypes = FetchUserTypeListAction | UserTypeListSuccessAction | FetchAppFeatureListAction
	| AppFeatureListSuccessAction | CreateUserTypeAction | UpdateUserTypeAction | DeleteUserTypeAction
	| ApiFailureAction | UserTypeStateResetAction;
import { callLogListSuccess, initCallLogListFetch, logFailure } from './actions';
import logReducer, { logInitialState } from './reducer';
import { useCallback, useReducer } from 'react';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { CallLogData } from '../../types/call-log-data';
import CallLogFilterRequest from '../../types/call-log-filter-request';
import { doPost } from '../../service';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to handle call log related actions and state.
 *
 * Provides functions to fetch call logs and the current state of the call log list.
 *
 * @returns {{ fetchCallLogList: Function, state: object }} An object containing the `fetchCallLogList` function and the current call log state.
 */
export function useLogApi() {

	const [state, dispatch] = useReducer(logReducer, logInitialState);
	const { t } = useTranslation();

	/**
   * Dispatches a failure action with an error message.
   *
   * @param {any} error - The error object.
   */
	const dispatchFailureAction = (error: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(logFailure(new AppError(error?.code, message)));
	}

	/**
   * Fetches the call log list based on the provided filter request.
   *
   * @param {CallLogFilterRequest} request - The filter request object.
   */
	const fetchCallLogList = useCallback(async (request: CallLogFilterRequest) => {
		dispatch(initCallLogListFetch());
		try {
			const callLogList: Array<CallLogData> = await doPost(APIConfig.callLog, request);
			callLogList.sort((a, b) => b.summary.createdAt.localeCompare(a.summary.createdAt));
			dispatch(callLogListSuccess(callLogList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	return {
		fetchCallLogList,
		state
	};
}